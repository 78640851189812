import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import MEDIA from 'helpers/mediaTemplates';
import { PhoneIcon } from '@chakra-ui/icons';
import { contentContainer } from '../constants/theme';
// import { accentText } from '../constants/theme';
import { myContext } from '../store/provider';
import { trackCustomEvent } from '../helpers/functions';
import { motion } from 'framer-motion';
import Button3D from './button3D';

const Hero = ({ lang = 'es' }) => {
  const data = useStaticQuery(graphql`query ImagenesHero {
  icono3dFluid: file(relativePath: {eq: "images/visualma-3D-MexicoOjo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 150, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
    }
  }
}
`);
  // const [ref, inView] = useInView({
  //   rootMargin: '-100px 0px',
  //   triggerOnce: true,
  // });
  const { openDrawer } = useContext(myContext);
  const openContacto = () => {
    trackCustomEvent('open_contacto', {
      event_category: 'contacto',
      event_label: 'Contacto Hero Home',
      non_interaction: true,
      value: 1,
    });

    openDrawer();
  };

  return (
    // <AnimatedDiv>
    // </AnimatedDiv>
    <div>
      <Container marginTop={[0, "26vh"]} marginBottom={[0, "26vh"]}>
        <div className="texto">
          <h1 className="heroSlogan">
            {lang === 'en' ? (
              <>
                <span className="accent">We specialize in </span>
                Virtual Production, Virtual Reality, Augmented Reality, 3D Mapping & Animation
              </>
            )
            : (
              <>
                <span className="accent">Nos especializamos en </span>
                Producción Virtual, 
                Animación, Realidad Virtual, Realidad Aumentada y Mapping 3D en México
              </>
            )}
          </h1>
          <div className="btnWrapper">
            <motion.div 
              animate={{x: '0px', opacity: 1 }}
              initial={{x: '-200px', opacity: 0 }}>
              <Link
                className="btnCta"
                to="/contacto"
                onClick={(e) => {
                  e.preventDefault();
                  openContacto();
                }}
              >
                <Button3D size="lg">
                  <PhoneIcon mr={4} mt={1} />
                  {lang === 'en' ? 'Contact us' : 'Contáctanos'}
                </Button3D>
              </Link>
            </motion.div>
          </div>
        </div>
        <div className="imagen">
          <GatsbyImage
            image={data.icono3dFluid.childImageSharp.gatsbyImageData}
            alt="Visualma México - 3d" loading="eager"/>
        </div>
      </Container>
    </div>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${contentContainer}
  padding: 8em 3rem 8em; //3rem 3rem 4.5rem;

  .heroSlogan {
    font-size: calc(1.375rem + 1.4vw); //44px;
    line-height: 1.2;
    font-weight: bold;
    padding: 0;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    font-family: "Inter", "Helvetica Neue", "Segoe UI", Helvetica, "Nimbus Sans L", "Liberation Sans", "Open Sans", FreeSans, sans-serif;
  }
  .heroSlogan .accent {
    color: hsl(201deg 91% 37%);
  }

  .imagen {
    flex: 0 1 auto;
    align-self: auto;
    width: 350px;
    margin-right: 4rem;
    ${MEDIA.TABLET`
      width: 300px;
      max-width: 40vw;
    `};
    ${MEDIA.PHONE`
      margin-right: 0rem;
      margin-top: -3em;
    `};
  }
  .texto {
    flex: 1 1 auto;
    align-self: flex-start;
  }
  img {
    width: 300px;
    max-width: 100%;
  }
  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
  }
  a {
    transition: background-color 0.4s ease;
    text-decoration: none;
  }
  ${MEDIA.PHONE`
    flex-direction: column-reverse;
    justify-content: center;
    /* padding: 0 0 auto 1em; */
    padding: 3em 1em 3em;
    min-height: 58vh;
    .heroSlogan {
      font-size: 26px; //1.3em;
      line-height: 1.2;
      font-weight: bold;
      padding: 0;
      text-shadow: 0 1px 4px rgba(0,0,0,0.25);
    }
    .btnWrapper {
      max-width:90vw;
    }
  `};
`;

Hero.propTypes = {
  lang: PropTypes.string
};

export default Hero;
