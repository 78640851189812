//index component

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import styled from 'styled-components';
import Hero from 'components/hero';
import Servicios from 'components/servicios';
import Clientes from 'components/clientes';
import { Flex, Text } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import Section from '../components/section';
import Proyectos from '../components/proyectos';
// import Poster from '../../content/images/PosterDemoReelVisualmaMoz.jpg';
// import Poster from '../../content/images/animacion-3d-hotwheels4639c.jpg';
// import Poster from '../../content/images/Realidad-Virtual-Mexico-AdidasLab1.jpg';
import Poster from '../../content/images/visualma-vr-ar-3d.jpg';
import { VideoSchema } from 'helpers/schemaGenerator';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Button3D from '../components/button3D';
import { useTranslation } from '../helpers/i18n';
import { useGoal } from 'gatsby-plugin-fathom';

const Home = ({lang='es'}) => {
  const t = (key) => useTranslation(key, lang)

  const pageTitle =
    t('Video Mapping 3D, Realidad Virtual, Animación y Museos en México')
  const pageImage = Poster;
  const pageUrl = 'https://visualma.com';

  useEffect(() => {
    const autoPlayTimer = setTimeout(function () {
      document
        ?.querySelector('#demoReelFrame')
        ?.contentDocument?.querySelector('#startDemoReel')
        ?.click();
    }, 3000);

    return () => {
      clearTimeout(autoPlayTimer);
    };
  }, []);

  // const posterStatic = (
  //   <StaticImage
  //     src="../../content/images/PosterDemoReelVisualmaMoz.jpg"
  //     alt="Visualma Demo Reel Mexico"
  //     placeholder="blurred"
  //     layout="fullWidth"
  //     loading="eager"
  //   />)
  const mailGoal = useGoal('VPOUNW6Q')
  const callGoal = useGoal('GPFVVLSQ')
  return (
    <Layout lang={lang}>
      <Head lang={lang} pageTitle={pageTitle} />
      <VideoSchema
        videoUrl="https://player.vimeo.com/video/459961237"
        contentUrl={pageUrl}
        imageUrl={pageImage}
        title={pageTitle}
        description={
          'Demo Reel 2020 - Ejemplos de Video Mapping 3D y animación en México por la agencia Visualma'
        }
        date="2020-07-10T19:03:44Z"
        duration="PT1M46S"
      />
      <VideoWrapper>
        <iframe
          id="demoReelFrame"
          width="720"
          height="405"
          src="https://player.vimeo.com/video/459961237?autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=1&muted=1&playsinline=1"
          srcDoc={`
          <style>
          * {
            padding: 0;
            margin: 0;
            overflow: hidden
          }
        
          html,
          body {
            height: 100%;
            background: black
          }
        
          img,
          span {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            margin: auto
          }
        
          span {
            height: 260px;
            text-align: center;
            font: 100px/1.5 sans-serif;
            color: white;
            text-shadow: 6px 6px 6px black;
            
          }
          svg {
            color: rgb(8 120 180 / 66%);
            width: 124px;
            height: 124px;
            filter: drop-shadow(6px 6px 6px black);
            
            transition: transform .5s ease-in-out, filter .5s ease-in-out;
            position: relative;
            top: 48px;
          }
          @media (max-width: 600px) {
            svg {
              width: 98px;
              height: 98px;
            }
          }
          a:hover svg {
            transform: scale(1.3) ;
            filter: drop-shadow(6px 16px 8px black);
          }
          a:hover svg:hover {
            transform: scale(1.5) ;
          }
          
        </style>
        <a id='startDemoReel'
          href=https://player.vimeo.com/video/459961237?autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=1&muted=1&playsinline=1">
          <img src=${Poster} loading="eager" alt='Visualma Demo Reel Mexico'>
          <span>
            <!--
            <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path fill="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
            </svg>
            <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
            </svg>
            -->
            
            <svg fill="currentColor"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path clip-rule="evenodd" fill-rule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" />
            </svg>
          </span>
          
        </a>
          `}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Visualma Demo Reel"
          loading="lazy"
          style={{ background: '#222' }}
        />
      </VideoWrapper>
      <Hero lang={lang} />
      <Servicios lang={lang} />
      <Clientes lang={lang} />
      <Proyectos lang={lang}  category="" limit={6} title=" &nbsp;"
      // title={lang === 'en' ? 'Projects' : 'Proyectos'}
      />
      
      <Section
        background="#fff"
        title={
          <Text
            bgGradient="linear(to-r,#ff0000, var(--accent-color), #7928CA, #FF0080)"
            bgClip="text"
            textShadow="none"
            // fontSize="6xl"
            fontWeight="extrabold"
            fontSize={['2.25rem', 'calc(3.95rem + 1.6vw)']}
            letterSpacing={['-.8px', '-2.8px']}
          >
            
            {t('¡Hagamos realidad tu proyecto!')}
            {/* Creamos <b style={{ color: 'var(--accent-color-txt)' }}>experiencias impactantes</b>{' '}
            que conectan a clientes y marcas más allá de los medios tradicionales. */}
          </Text>
        }
        contentHeight="auto"
      >
        <Flex
          textAlign="center"
          color="white"
          p="0"
          flexDirection="column"
          alignItems="center"
        >
          <Flex justifyContent="center" flexDirection="column" m="2em auto 5em">
            <a
              href="tel:3338253793"
              style={{
                color: 'var(--accent-color-txt)',
                fontSize: '24px',
                textDecoration: 'none',
                fontWeight: 'bold',
                marginBottom: '1.4em'
              }}
              // color="azulContrast.600"
              onClick={() => { callGoal() }}
            >
              <PhoneIcon mr="8px" /> {t('33 3825-3793')}
            </a>
            <OutboundLink
              href={`mailto:${t('contacto@visualma.com')}`}
              target="_blank"
              onClick={() => { mailGoal() }}
            >
              <Button3D size="lg">
                <EmailIcon mr="4" mt={1}/> 
                {t('contacto@visualma.com')}
              </Button3D>
            </OutboundLink>
          </Flex>
        </Flex>
      </Section>
    </Layout>
  );
};

Home.propTypes = {
  lang: PropTypes.string
};

// export const query = graphql`query HomepageQuery {
//   fondoV: file(relativePath: {eq: "images/fondoV.jpg"}) {
//     childImageSharp {
//       gatsbyImageData(width: 300, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
//     }
//   }
// }
// `;

const VideoWrapper = styled.section`
  width: 100vw;
  max-width: 100%;
  margin: 0 auto 2rem;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export default Home;
