import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Section from '../components/section';
import { Grid } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { animatedItemVar } from '../constants/poseTemplates';
import { accentText } from '../constants/theme';


const Cliente = ({ children }) => (
  
    <motion.div 
      // initial={{scale: 1 }}
      // animate="animate"
      // initial="initial"
      whileHover={{ scale: 1.53 }}
      whileTap={{ scale: 1.1 }}
      // transition={{
      //   delay: 7.3}}
      variants={animatedItemVar}
    >  
      {children}
    </motion.div>
  
);
Cliente.propTypes = {
  children: PropTypes.any,
};

const Clientes = ( {lang='es'} ) => {
  const data = useStaticQuery(graphql`query ImagenesClientes {
  mattel: file(relativePath: {eq: "images/clientes/Mattel-Animacion-3D.png"}) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  adidas: file(relativePath: {eq: "images/clientes/Adidas-video-mapping.png"}) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  siemens: file(
    relativePath: {eq: "images/clientes/Siemens-presentacion-ejecutiva.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  tvAzteca: file(relativePath: {eq: "images/clientes/TV_Azteca.png"}) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  josecuervo: file(
    relativePath: {eq: "images/clientes/JoseCuervo-video-proyeccion.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  epura: file(
    relativePath: {eq: "images/clientes/Epura-Presentacion-de-producto-mapping-4D.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  jalisco: file(
    relativePath: {eq: "images/clientes/Gobierno-Jalisco-Mapping.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  bticino: file(
    relativePath: {eq: "images/clientes/bticino-presentacion-corporativa.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  puebla: file(relativePath: {eq: "images/clientes/Puebla-museo-interactivo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  mazda: file(relativePath: {eq: "images/clientes/Mazda-Mapping.png"}) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  seleccion: file(
    relativePath: {eq: "images/clientes/Seleccion-Mexicana-Football-video.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 60, quality: 60, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
  camel: file(
    relativePath: {eq: "images/clientes/Camel-presentacion-ejecutiva.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 240, quality: 70, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
    }
  }
}
`);
  return (
    <Section 
      background="#fff"
      color={accentText}
      title={lang === 'en' ? 'Recent Clients' : 'Clientes recientes'}>
      <InnerContent>
        <Grid
          templateColumns={['repeat(3, 1fr)', 'repeat(4, 1fr)']}
          gap={2}
          alignItems="center"
          justifyItems="center"
          pb="5em"
        >
          <Cliente>
            <a href="/proyectos/mapping-3d-mattel-toyfair-2015">
              <GatsbyImage
                image={data.mattel.childImageSharp.gatsbyImageData}
                alt="3D Mapping y Animación 3D para Mattel México" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/video-mapping-3d-adidas-seleccion-mexicana">
              <GatsbyImage
                image={data.adidas.childImageSharp.gatsbyImageData}
                alt="Adidas - Mapping 3D de presentación de la selección Méxicana, balones y zapatos en México" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/3d-mapping-cuervo-110">
              <GatsbyImage
                image={data.josecuervo.childImageSharp.gatsbyImageData}
                alt="JoseCuervo - 3D Mapping en Tequila, Guadalajara, Mexico" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/epura-espectaculo-de-mapping-4d-con-sonido-7-1-en-mexico">
              <GatsbyImage
                image={data.epura.childImageSharp.gatsbyImageData}
                alt="Epura - Color code 3D Mapping, presentacion de nueva imagen, Show 4D, sonido 8.1 surround, México" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos">
              <GatsbyImage
                image={data.mazda.childImageSharp.gatsbyImageData}
                alt="Video mapping interactivo con actores para Mazda México" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/3d-mapping-siemens-aniversario-120-anos-en-mexico">
              <GatsbyImage
                image={data.siemens.childImageSharp.gatsbyImageData}
                alt="Video Mapping para presentación ejecutiva de Siemens México" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/3d-mapping-bticino-ciudad-quinzino/">
              <GatsbyImage
                image={data.bticino.childImageSharp.gatsbyImageData}
                alt="bTicino - 3D Mapping en monterrey, Guadalajara y Mexico D.F." />
            </a>
          </Cliente>

          <Cliente>
            <a href="/proyectos/3d-mapping-balon-aspid-limpiemos-nuestro-mexico">
              <GatsbyImage
                image={data.tvAzteca.childImageSharp.gatsbyImageData}
                alt="Presentación del balon de la seleccion mexicana de futbol en TV Azteca México" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos">
              <GatsbyImage
                image={data.camel.childImageSharp.gatsbyImageData}
                alt="Presentacion de mercadotecnia para Camel Mexico" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/instalacion-multimedia-ciudad-i-o">
              <GatsbyImage
                image={data.jalisco.childImageSharp.gatsbyImageData}
                alt="Instalacion multimedia para museo del gobierno de Guadalajara, Jalisco" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/mapping-3d-360-museo-de-la-musica-de-puebla/">
              <GatsbyImage
                image={data.puebla.childImageSharp.gatsbyImageData}
                alt="Museo de Cholula, Estado de Puebla" />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/video-mapping-3d-adidas-seleccion-mexicana/">
              <GatsbyImage
                image={data.seleccion.childImageSharp.gatsbyImageData}
                alt="Video Mapping para presentacion de playera de la selección mexicana" />
            </a>
          </Cliente>
          {/*
          <Cliente>
            <a href="/proyectos/mapping-diseno-jose-cuervo">
              <Img
                fluid={data.donjulio.childImageSharp.fluid}
                alt="Tequila DonJulio - Proyección de 3D video Mapping para publicitar marcas y empresas"
              />
            </a>
          </Cliente>
           <Cliente>
            <a href="/proyectos/mapping-3d-xx-festival-internacional-de-cine-de-morelia">
              <Img
                fluid={data.morelia.childImageSharp.fluid}
                alt="Fiesta de clausura de festival de cine de morela 2013 - Espectaculos para festivales nacionales e internacionales"
              />
            </a>
          </Cliente>

          <Cliente>
            <Img
              fluid={data.telcel.childImageSharp.fluid}
              alt="Telcel - 3D Mapping en Guadalajara, Mexico"
            />
          </Cliente>
          
          <Cliente>
            <a href="/proyectos/kraken-rum-primer-mapping-3d-anaglifo-en-mexico">
              <Img
                fluid={data.kraken.childImageSharp.fluid}
                alt="Kraken - 3D Mapping Con lentes anaglypho en Cancun, Monterrey, Guadalajara y Mexico D.F."
              />
            </a>
          </Cliente>
          <Cliente>
            <Img
              fluid={data.heineken.childImageSharp.fluid}
              alt="Heineken - 3D Mapping en Guadalajara, Mexico"
            />
          </Cliente>
          <Cliente>
            <Link to="/proyectos">
              <Img
                fluid={data.nextel.childImageSharp.fluid}
                alt="Nextel - 3D Mapping, presentacion de producto & show Room en Queretaro, México"
              />
            </Link>
          </Cliente>
          <Cliente>
            <a href="/proyectos/mapping-3d-xx-festival-internacional-de-cine-de-morelia">
              <Img
                fluid={data.xx.childImageSharp.fluid}
                alt="XX - Fiesta de clausura de festival de cine de morela 2013 - Cerveza XX - Mapping 3d para Eventos y fiestas masivas"
              />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos">
              <Img
                fluid={data.domeq.childImageSharp.fluid}
                alt="Video domo para Pedro Domeq Mexico"
              />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/mapping-para-presentacion-de-playera-chivas/">
              <Img
                fluid={data.chivas.childImageSharp.fluid}
                alt="Video presentación de la playera de Futball de chivas de Guadalajara"
              />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/3d-360-mapping-stand-mansarovar-energy/">
              <Img
                fluid={data.mansarovar.childImageSharp.fluid}
                alt="Video Mapping 360º con lentes 3D a color - Stand para exposición convencion en expo petrolera Vallarta, México"
              />
            </a>
          </Cliente>
          <Cliente>
            <a href="/proyectos/instalacion-interactiva-museo-del-titere-puebla/">
              <Img
                fluid={data.titere.childImageSharp.fluid}
                alt="Holograma interactivo Museo del Titere Puebla"
              />
            </a>
          </Cliente> */}
        {/* <Cliente>
            <Button as={Link}
              to={'/proyectos'}
              textDecoration="none"
              colorScheme="azulin"
              variant="ghost"
              mt="3"
              size="sm"
              rightIcon={<ExternalLinkIcon/>}>
              Ver mas
        </Button>
        </Cliente> */}
        </Grid>
        
      </InnerContent>
    </Section>
  );
};

const InnerContent = styled.div`
  .gatsby-image-wrapper {
    width: 120px;
    max-width: 100%;
  }
  img {
    max-width: 120px;
    margin: 0 auto;
  }
  @media (max-width: 900px) {
    img {
      max-width: 80px;
    }
    .gatsby-image-wrapper {
      width: 80px;
      max-width: 100%;
    }
  }
  @media (max-width: 550px) {
    img {
      max-width: 60px;
      width: 60px;
    }
    .gatsby-image-wrapper {
      width: 60px;
      max-width: 100%;
    }
  }
  a {
    text-decoration: none;
    color: #333;
  }
`;
Clientes.propTypes = {
  lang: PropTypes.string,
};
export default Clientes;
