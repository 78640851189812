import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { SimpleGrid, Box, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from './section';
import TexturaFondo from '../../content/images/texturaFondo.png';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@react-hook/media-query';
import { getLocalizedPath, useTranslation } from '../helpers/i18n';

const Servicio = (props) => {
  const smallDevice = useMediaQuery('(max-width: 600px)');
  return (
    // <AnimatedItem> style={{ transform: 'translateY(0px) scale(1) translateZ(0px)'}}
    // <MotionBox variants={animatedItemVar} >
      <motion.div
        initial={{ y: '0px', scale: 1, zIndex: 1 }}
        animate={{y: '0px'}}
        // transition={{ ease: "easeOut", duration: 0.02 }}
        whileHover={smallDevice ? { scale: 1 } : { scale: 1.1, zIndex: 2, borderRadius: '16px', filter: 'drop-shadow(rgba(0, 0, 0, 0.9) 3px 20px 28px)' }}
        whileTap={smallDevice ? { scale: 1 } : { scale: 0.95 }}
        style={{ height: '100%',
          contentVisibility: 'auto',
          containIntrinsicSize: '400px 286px',
          borderRadius: '8px',
          filter: 'drop-shadow(rgba(0, 0, 0, 0.7) 3px 3px 4px)'
        }}
      >
        <Link to={props.url ? props.url : ''}>
          <Box
            bg="white"
            height="100%"
            overflow="hidden"
            // boxShadow="2px 2px 6px rgba(0, 0, 0, 0.3)"
          >
            <Heading
              as="h3"
              size="md"
              textAlign="center"
              mt="-16px"
              overflow="hidden"
              fontSize="calc(1.375rem + 1.6vw)"
              minWidth={'220px'}
              borderRadius={'8px'}
            >
              {props.title}
            </Heading>
            <GatsbyImage
              image={getImage(props.imgSrc)}
              alt={`${props.title} en México`}
              style={{ margin: '0 auto', maxHeight: 'auto', minHeight: '180px', width: '100%' }} />
            <Text
              ml={5} mr={5} mt={4} mb={5}
              textDecoration="none"
              // color="#343434"
              overflow="hidden"
              minHeight="67px"
              lineHeight="1.4"
              fontSize={['18px', '18px', '18px', '18px']}
              fontWeight={'bold'}
              color="#6d6d6d"
            >
              {props.description}
            </Text>
          </Box>
        </Link>
      </motion.div>
  );};
Servicio.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.any.isRequired,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const Servicios = ({lang = 'es'}) => {
  const data = useStaticQuery(graphql`query ImagenesServicios {
    realidadVirtual: file(
      relativePath: {eq: "images/servicios/Realidad_Virtual_Mexico3_comp.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    realidadAumentada: file(
      relativePath: {eq: "images/servicios/Realidad-Aumentada-en-MexicoExp.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    mapping: file(
      relativePath: {eq: "images/servicios/VideoMapping_3d-Mexico-sm.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    animacion: file(
      relativePath: {eq: "images/servicios/Animacion3D-Proyeccion-Mexico.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    arquitectura: file(
      relativePath: {eq: "images/servicios/Recorrido-virtual-arquitectonico3.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    museos: file(
      relativePath: {eq: "images/servicios/Museos-Interactivos-en-Mexico3Exp3.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    virtualProd: file(
      relativePath: {eq: "images/servicios/escenarios/Escenarios-virtuales-camara-tracking-mexico5.jpeg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
    capacitacion: file(
      relativePath: {eq: "images/servicios/capacitacion/capacitacion-realidad-virtual-p.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 560, quality: 80, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
  `);
  const t = (key) => useTranslation(key, lang)
  const tPath = (key) => getLocalizedPath(key, lang)

  return (
    <Fondo>
    <Section
      // backgroundNo={`#222 url(${TexturaFondo})`}
      // background="#e8e8e8"
      background="transparent"
      title={t('Servicios')}
      color="#FFF" //var(--accent-color-txt)"
      fullWidth={true}
      centerVerticaly={true}
      ani
    >
      <Content>
        <SimpleGrid
          columns={[1, 2, 2, 3,3,4]}
          spacing={['40px', '20px', '12px', '12px']}
          paddingBottom="4rem"
        >
          <Servicio
            title={t('Capacitación con Realidad Virtual')}
            url={tPath("/capacitacion-realidad-virtual")}
            imgSrc={data.capacitacion.childImageSharp.gatsbyImageData}
            description={t('Utiliza la Realidad Virtual como una herramienta invaluable para el entrenamiento de tus empleados.')}
          />
          <Servicio
            title={t('Realidad Aumentada')}
            url={tPath("/realidad-aumentada")}
            imgSrc={data.realidadAumentada.childImageSharp.gatsbyImageData}
            description={t('Experiencias de Realidad Aumentada que combinan el mundo real con el mundo digital en tiempo real.')}
          />
          <Servicio
            title={t('Realidad Virtual')}
            url={tPath("/realidad-virtual")}
            imgSrc={data.realidadVirtual.childImageSharp.gatsbyImageData}
            description={t('Mundos interactivos envolventes que permiten que el usuario viva el universo de nuestros clientes.')}
          />
          <Servicio
            title={t('Animación 3D y Video')}
            url={tPath("/animacion-modelado-3d")}
            imgSrc={data.animacion.childImageSharp.gatsbyImageData}
            description={t('Contenidos animados y modelado 3D de alto nivel para cinematografía, televisión, marketing y publicidad.')}
          />
          <Servicio
            title={t('Producción Virtual')}
            url={tPath("/set-virtual-produccion-cine-tv")}
            imgSrc={data.virtualProd.childImageSharp.gatsbyImageData}
            description={t('Producción virtual y Escenarios Virtuales para Producciónes de TV, Publicidad, Cine y Broadcast en vivo.')}
          />
          <Servicio
            title={t('Video Mapping 3D')}
            url={tPath("/video-mapping")}
            imgSrc={data.mapping.childImageSharp.gatsbyImageData}
            description={t('Poderosas ilusiones sobre fachadas y escenarios que impactan a una gran cantidad de espectadores.')}
          />
          <Servicio
            title={t('Arquitectura')}
            url={tPath("/arquitectura-recorrido-virtual")}
            imgSrc={data.arquitectura.childImageSharp.gatsbyImageData}
            description={t('Recorridos virtuales arquitectonicos interactivos y personalizables que comunican proyectos y cierran ventas.')}
          />
          <Servicio
            title={t('Museos')}
            url={tPath("/museos-instalaciones")}
            imgSrc={data.museos.childImageSharp.gatsbyImageData}
            description={t('Instalaciónes audiovisuales que logran visibilidad en medios y atraen nuevos públicos a museos y exposiciones.')}
          />

          {/* <Servicio
            title={t('Aplicaciones y Videojuegos')}
            url={tPath("/aplicaciones-videojuegos")}
            imgSrc={data.interactividad.childImageSharp.fluid}
            description={t("Juegos y aplicaciónes para dispositivos móviles, web, pc's y consolas.")}
          /> */}
        </SimpleGrid>
      </Content>
    </Section>
    </Fondo>
  );
};
Servicios.propTypes = {
  lang: PropTypes.string,
};
const Fondo = styled.div`
@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 180deg;
}
background: linear-gradient(var(--a), rgb(0 0 0), rgb(21, 120, 174));
background: linear-gradient(var(--a), hsl(201deg 90% 37%) 0%,  hsl(201deg 100% 38%) 11%,  hsl(202deg 100% 41%) 22%,  hsl(203deg 100% 44%) 34%,  hsl(203deg 100% 47%) 45%,  hsl(204deg 100% 50%) 56%,  hsl(206deg 100% 61%) 67%,  hsl(206deg 100% 67%) 76%,  hsl(206deg 99% 72%) 85%,  hsl(205deg 98% 77%) 93%,  hsl(204deg 96% 82%) 100%);
// background: linear-gradient(var(--a), hsl(204deg 100% 74%) 0%,  hsl(204deg 82% 68%) 9%,  hsl(204deg 69% 61%) 21%,  hsl(204deg 61% 54%) 38%,  hsl(203deg 63% 47%) 57%,  hsl(201deg 90% 37%) 73%,  hsl(208deg 45% 54%) 84%,  hsl(210deg 41% 64%) 91%,  hsl(212deg 36% 73%) 96%,  hsl(213deg 27% 82%) 99%,  hsl(0deg 0% 91%) 100%);

animation: gradientRotation 12s alternate ease-in-out infinite;
@keyframes gradientRotation {
  from {
    --a:180deg;
    }
    to {
    --a:540deg;
    }
}`;

const Content = styled.section`
  h3 {
    color: #fff;
    font-size: 1.5em;
    font-size: calc(1.525rem + 0.7vw);
    letter-spacing: -0.03em;
    /* letter-spacing: -1px; */
    line-height: 1.2;
    font-weight: bold;
    /* text-transform: capitalize; */
    padding: 0;
    text-shadow: 2px 2px 3px rgb(6, 6, 6);
    position: absolute;
    z-index: 2;

    padding: 0rem 0.4rem 0.2rem; //0.1rem 0.5rem;
    // background-color: #00000022;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: fit-content;
    width: 310px;
  }
  a {
    text-decoration: none;
  }
  a:hover h3 {
    text-shadow: none;
    text-shadow: 1px 2px 2px #00496f;

    animation: gradientSizeAnim 8s ease-in-out forwards;

    color: var(--accent-color-txt);
    background: -webkit-linear-gradient(
      to right,
      #ffffff 0%,
      #ffffff 5%,
      #01579b33 15%,
      #ffffff 22%,
      #ffffff 100%
    );
    background: linear-gradient(
      45deg,
      #ffffff 0%,
      #ffffff 5%,
      #01579b33 15%,
      #ffffff 22%,
      #ffffff 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none !important;
    text-shadow: 1px 2px 2px #00496f;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  @keyframes gradientSizeAnim {
    0% {
      background-size: 3000%;
    }
    40% {
      background-size: 100%;
    }
    100% {
      background-size: 1400%;
    }
  }
`;

export default Servicios;
